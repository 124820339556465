import type { StandardEvent } from './standard-events';

export type Gtag = (...args: Array<unknown>) => void;

declare global {
	interface Window {
		dataLayer: Array<Array<unknown>>;
		gtag: Gtag;
	}
}

export function getScript(code: string): [string, string] {
	return [
		`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${code}');`,
		`https://www.googletagmanager.com/gtag/js?id=${code}`,
	];
}

export const eventMap: Record<StandardEvent, string | null> = {
	'add-payment-info': null,
	'add-to-cart': null,
	'complete-registration': null,
	contact: null,
	'initiate-checkout': null,
	lead: 'PGFcCOuX3tUZEOzewaE-',
	'page-view': 'IFTeCO6X3tUZEOzewaE-',
	purchase: 'cZs5COiX3tUZEOzewaE-',
	search: null,
	'start-trial': null,
	'submit-application': null,
	subscribe: null,
	'view-content': null,
};

export function trackGtag(event: StandardEvent, data?: Record<string, unknown>) {
	if (!eventMap[event] || !window.gtag) {
		return;
	}

	window.gtag('event', 'conversion', { ...data, send_to: `AW-16713281388/${eventMap[event]}` });
}
